@import "https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Raleway:wght@300;400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800&display=swap";
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

root {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color-scheme: light dark;
  color: #333333;
  background: #ffffff;
}

@media (prefers-color-scheme: dark) {
  :root {
    color: #333333;
    background: #ffffff;
  }
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DM Serif Display", serif;
  font-weight: 400;
}

button {
  font-family: "Raleway", sans-serif;
}

.react-tel-input .selected-flag {
  padding-right: 12px !important;
  height: 45px !important;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border-color: transparent !important;
}
.error-msg .react-select__control {
  border: 2px solid red;
}
.react-select-container .react-select__single-value{
  font-family: 'Raleway', sans-serif !important;
}


.react-select__indicator-separator {
  display: none;
}

.react-select__indicator.react-select__dropdown-indicator svg {
  fill: #6243ce;
}

.test {
  background: #000;
}
